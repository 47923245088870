.auth {
  position: relative;
  width: 100%;

  h2 {
    font-size: 22px;
    @include proximanova-bold;
  }

  .col {
    padding: 87px 115px;
    display: flex;
    flex-direction: column;
  }

  &-signin {
    padding-top: 87px;
    background: #1E22AA;

    h2, p {
      color: #FFFFFF;
    }

    &--bg {
      background: #1E22AA;
      left: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      width: 50%;
    }
  }

  &-registration {
    background: #FFFFFF;

    h2, p {
      color: #1726A6;
    }

    &--bg {
      right: 0;
      top: 0;
      bottom: 0;
      background: #FFFFFF;
      position: absolute;
      width: 50%;
    }
  }

  button {
    width: fit-content;
    margin-top: auto;
  }
}

#welcome {
  background: #0C141B;
  font-size: 18px;
  padding: 65px 0;
  position: relative;

  @media(max-width: 768px) {
    text-align: center;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .row {
    align-items: center;
  }

  img {
    width: 100%;
  }

  @keyframes move-twink-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -10000px 5000px;
    }
  }
  @-webkit-keyframes move-twink-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -10000px 5000px;
    }
  }
  @-moz-keyframes move-twink-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -10000px 5000px;
    }
  }
  @-ms-keyframes move-twink-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -10000px 5000px;
    }
  }

  @keyframes move-clouds-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 10000px 0;
    }
  }
  @-webkit-keyframes move-clouds-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 10000px 0;
    }
  }
  @-moz-keyframes move-clouds-back {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 10000px 0;
    }
  }
  @-ms-keyframes move-clouds-back {
    from {
      background-position: 0;
    }
    to {
      background-position: 10000px 0;
    }
  }

  .stars, .twinkling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  .stars {
    background: #000 url(../images/stars.png) repeat top center;
    z-index: 0;
  }

  .twinkling {
    background: transparent url(../images/twinkling.png) repeat top center;
    z-index: 1;

    -moz-animation: move-twink-back 200s linear infinite;
    -ms-animation: move-twink-back 200s linear infinite;
    -o-animation: move-twink-back 200s linear infinite;
    -webkit-animation: move-twink-back 200s linear infinite;
    animation: move-twink-back 200s linear infinite;
  }
}
@font-face {
  font-family: ProximaNovaLight;
  src: url(../fonts/ProximaNovaLight.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ProximaNovaRegular;
  src: url(../fonts/ProximaNovaRegular.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ProximaNovaSemibold;
  src: url(../fonts/ProximaNovaSemibold.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ProximaNovaBold;
  src: url(../fonts/ProximaNovaBold.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
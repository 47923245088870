.input-group {

  &--label {
    display: block;
    font-size: 14px;
  }

  &--select {
    margin-top: 3px;
  }

  &--input {
    display: block;
    min-width: 260px;
    height: 40px;
    margin-top: 3px;
    padding: 10px 10px;
    border: 1px solid #929292;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #C4C4C4;
    }
  }

  &--half-input {
    min-width: 120px;
    width: 120px;
  }

}

.input-group.has-error {
  input, textarea {
    border-color: $colorRed2;
  }

  .help-block, .errorlist {
    color: $colorRed2;
    font-size: 10px;
    margin: 0;
    padding-inline-start: 15px;
    padding-top: 3px;
  }
}

.checkbox-group, .radio-group {
  display: flex;
  cursor: pointer;

  &--input {
    flex: none;
    position: relative;
    cursor: pointer;
  }

  &--label {
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
  }
}

textarea.input-group--input {
  min-height: 60px;
}

.input-placeholder {
  position: relative;
  width: fit-content;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    padding: 8px 10px;
  }

  &--date {
    &::after {
      height: 20px;
      width: 20px;
      top: 10px;
      right: 10px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.626 2.3125H14.6885V0.8125C14.6885 0.709375 14.6041 0.625 14.501 0.625H13.1885C13.0854 0.625 13.001 0.709375 13.001 0.8125V2.3125H7.00098V0.8125C7.00098 0.709375 6.9166 0.625 6.81348 0.625H5.50098C5.39785 0.625 5.31348 0.709375 5.31348 0.8125V2.3125H1.37598C0.961133 2.3125 0.625977 2.64766 0.625977 3.0625V18.625C0.625977 19.0398 0.961133 19.375 1.37598 19.375H18.626C19.0408 19.375 19.376 19.0398 19.376 18.625V3.0625C19.376 2.64766 19.0408 2.3125 18.626 2.3125ZM17.6885 17.6875H2.31348V8.78125H17.6885V17.6875ZM2.31348 7.1875V4H5.31348V5.125C5.31348 5.22813 5.39785 5.3125 5.50098 5.3125H6.81348C6.9166 5.3125 7.00098 5.22813 7.00098 5.125V4H13.001V5.125C13.001 5.22813 13.0854 5.3125 13.1885 5.3125H14.501C14.6041 5.3125 14.6885 5.22813 14.6885 5.125V4H17.6885V7.1875H2.31348Z' fill='%231B27A3'/%3E%3C/svg%3E%0A");
    }
  }

  &--money {
    &::after {
      content: 'руб.';
    }
  }

  &--people {
    &::after {
      content: 'чел.';
    }
  }
}
header {
  background: $colorBlack;
  color: $colorWhite;
  z-index: 5;
  position: relative;

  .container {
    position: relative;
  }

  .logo {
    padding: 5px 55px 0 0;
    z-index: 4;

    img {
      height: 34px;
    }

    @media (max-width: 786px) {
      padding: 5px 0 0 0;
    }
  }

  .hamburger {
    display: none;

    @media (max-width: 992px) {
      display: block;
      z-index: 4;
    }
  }

  .header-link {
    text-decoration: none;
    color: $colorGrey;
    font-size: 14px;
    display: inline-block;
    border: 1px solid $colorWhite;
    padding: 4px 10px;
    z-index: 4;

    &:hover {
      background: $colorWhite;
      color: $colorBlack;
    }

    @media (max-width: 786px) {
      display: none;
    }
  }

  @media (max-width: 992px) {
    position: fixed;
    width: 100%;
  }

  .menu {
    margin-right: 31%;

    &-nm {
      margin: 0px;
    }

    li {
      padding: 0 25px 0 0;
    }
  }
}

body {

  &.show-menu {
    overflow: hidden;
  }
}

#site-content {
  @media (max-width: 992px) {
    padding: 60px 0 0 0;
  }
}

.header-wrapper {
  display: flex;
  align-items: center;
  min-height: 60px;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}

.top-main-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 992px) {
    display: none;
    position: fixed;
    top: 0;
    background: $colorBlack;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 3;
    padding: 60px 0 30px;
    overflow-y: auto;

    &:after {
      content: '';
      position: fixed;
      top: 0;
      background: $colorBlack;
      width: 100%;
      height: 60px;
      left: 0;
      z-index: 3;
    }

    ul {
      display: block;
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;

      li, li:last-child {
        padding: 30px 0 0 0;

        @media (max-width: 992px) {
          padding: 0;
        }
      }
    }
  }
}

#top {
  background: url(/images/bg.jpg);
}


.menu {
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-wrap: wrap;
  transition: all 0.3s ease-out;
  justify-content: flex-end;

  li {
    padding: 0 40px 0 0;
    height: $headerGap;
    display: flex;
    align-items: center;

    &.current-menu-item {

      a {
        &:before, &:after {
          width: 100%;
        }
      }
    }

    .sub-menu {
      position: absolute;
      background: #fff;
      left: 0;
      top: 60px;
      right: 0;
      color: #000;
      border-bottom: 3px solid $colorBlue;
      border-top: 3px solid $colorBlue;
      margin: 0 15px;
      padding: 40px 0 20px 0;
      font-size: 16px;
      line-height: 1.25;
      display: none;

      a {

        &.link-color-black {
          color: $colorBlack;

          &:hover {
            color: $colorBlue;
            opacity: 1;

            &::after, &::before {
              display: none;
            }
          }
        }

        &.link-color-blue {
          color: $colorBlue;

          &:hover {
            color: $colorBlack;
            opacity: 1;

            &::after, &::before {
              display: none;
            }
          }
        }
      }

      @media (max-width: 992px) {
        display: block;
        position: relative;
        background: transparent;
        border: none;
        top: 0;
        padding: 20px 0 0px;
      }
    }

    .sub-menu-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 20px;

      a {
        margin: 0 0 15px 0;
      }

      div {
        padding: 0 0 10px 0;
      }

      &.display-1 {
        div:nth-child(7) {
          grid-column: 3;
          grid-row: 2/4;
        }

        div:nth-child(8) {
          grid-column: 4;
          grid-row: 2/4;
        }
      }

      &.display-2, &.display-3 {
        div:nth-child(5) {
          grid-column: 4/5;

          p {
            margin-bottom: 20px;
          }
        }
      }

      .mobile-hide {
        @media (max-width: 992px) {
          display: none;
        }
      }

      @media (max-width: 992px) {
        display: block;
      }

    }

    .sub-menu-links {
      a {
        display: block;
      }
    }

    &:hover .sub-menu {
      display: block;
    }

    @media (max-width: 992px) {
      justify-content: center;
      flex-direction: column;
      height: auto;

      a {
        color: $colorWhite !important;
        font-weight: 400 !important;
      }
    }
  }

  a {
    color: #FFFFFF;
    display: inline-block;
    position: relative;
    padding-bottom: 0px;
    transition: color .35s ease;

    &:active, &:focus {
      color: $colorWhite;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 0;
      transition: width 0s ease, background .35s ease;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: -2px;
      height: 2px;
      width: 0;
      background: $colorWhite;
      transition: width .35s ease;
    }

    &:hover:before {
      width: 100%;
      background: $colorWhite;
      transition: width .35s ease;
    }

    &:hover:after {
      width: 100%;
      background: transparent;
      transition: all 0s ease;
    }

    &:hover {
      color: $colorWhite;
    }

    &.link-logout:after {
      content: ' ';
      background: none;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.79954 0.933594C2.55201 0.933594 2.31461 1.03193 2.13958 1.20696C1.96454 1.38199 1.86621 1.61939 1.86621 1.86693V12.1336C1.86621 12.3811 1.96454 12.6185 2.13958 12.7936C2.31461 12.9686 2.55201 13.0669 2.79954 13.0669H9.79955C9.92331 13.0669 10.042 13.0178 10.1295 12.9302C10.217 12.8427 10.2662 12.724 10.2662 12.6003C10.2662 12.4765 10.217 12.3578 10.1295 12.2703C10.042 12.1828 9.92331 12.1336 9.79955 12.1336H2.79954V1.86693H9.79955C9.92331 1.86693 10.042 1.81776 10.1295 1.73024C10.217 1.64273 10.2662 1.52403 10.2662 1.40026C10.2662 1.27649 10.217 1.15779 10.1295 1.07028C10.042 0.98276 9.92331 0.933594 9.79955 0.933594H2.79954ZM11.7633 4.56986C11.6757 4.48223 11.5568 4.433 11.4329 4.433C11.309 4.433 11.1901 4.48223 11.1025 4.56986C11.0149 4.65749 10.9656 4.77634 10.9656 4.90026C10.9656 5.02418 11.0149 5.14303 11.1025 5.23066L12.4063 6.53359H6.06621C5.94244 6.53359 5.82375 6.58276 5.73623 6.67028C5.64871 6.75779 5.59954 6.87649 5.59954 7.00026C5.59954 7.12403 5.64871 7.24273 5.73623 7.33024C5.82375 7.41776 5.94244 7.46693 6.06621 7.46693H12.4063L11.1025 8.76986C11.0149 8.85749 10.9656 8.97634 10.9656 9.10026C10.9656 9.22418 11.0149 9.34303 11.1025 9.43066C11.1901 9.51829 11.309 9.56752 11.4329 9.56752C11.5568 9.56752 11.6757 9.51829 11.7633 9.43066L13.8633 7.33066C13.9067 7.28731 13.9412 7.23581 13.9647 7.17912C13.9883 7.12242 14.0004 7.06164 14.0004 7.00026C14.0004 6.93888 13.9883 6.8781 13.9647 6.8214C13.9412 6.76471 13.9067 6.71321 13.8633 6.66986L11.7633 4.56986Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='14' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      width: 14px;
      height: 14px;
      right: -24px;
      top: 5px;
    }
  }
}

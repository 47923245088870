.selectize {
  .selectize-input {
    height: 40px;
    border: 1px solid #929292;
    border-radius: 0px;
    min-width: 260px;
    padding: 10px 10px;
  }
}

.selectize-dropdown, .selectize-input, .selectize-input input {
  font-size: 16px;
}

.has-error {
  .selectize .selectize-input {
    border: 1px solid #ff0001;
  }

  .selectize-control.single .selectize-input:after {
    border-color: #ff0001 transparent transparent transparent;
  }

  .selectize-control.single .selectize-input.dropdown-active:after {
    border-color: transparent transparent #ff0001 transparent;
  }
}
.step2-actions {
  margin-top: 40px;
  display: flex;
  height: 40px;

  a {
    margin-right: 20px;
  }

  button {
    width: 330px;
  }

  p {
    margin-left: 20px;
    font-size: 10px;
    width: 350px;
    position: relative;
    line-height: 100%;
  }
}
@mixin proximanova-regular {
  font-family: "ProximaNovaRegular", sans-serif;
  font-weight: 400;
}

@mixin proximanova-bold {
  font-family: "ProximaNovaBold", sans-serif;
  font-weight: 600;
}

@mixin proximanova-light {
  font-family: "ProximaNovaLight", sans-serif;
  font-weight: 300;
}
.service-menu-container {
  position: relative;
  color: #fff;
  background-color: #000;
}

.service-menu {
  overflow-x: auto;

  li {
    padding: 25px;
    width: 33.3333%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    white-space: nowrap;
    height: auto;

    a {
      color: #fff;
      display: inline-block;
      position: relative;
      padding-bottom: 0;
      -webkit-transition: color .35s ease;
      -o-transition: color .35s ease;
      transition: color .35s ease;
    }
  }
}

.btn {
  padding: 10px 30px;
  line-height: 1;
  min-height: 40px;
  min-width: 160px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  align-items: center;

  &:focus {
    outline: none;
  }

  &-text {
    font-size: 14px;
    border: none;
    background: none;
    padding: 0;

    span {
      margin-right: 10px;
    }
  }

  &-black-outline {
    background: #FFFFFF;
    border: 1px solid  #000000;
    color: #000000;
    &:hover {
      color: #000000;
    }
  }
}

label.btn {
  padding: 12px 30px;
}
a.btn {
  //line-height: 36px;
}

.btn:disabled, .btn.disabled {
  cursor: default;
  background: #BDBDBD;
  border: #BDBDBD;
  color: #FFFFFF;
  border: 2px solid #bdbdbd;

  &:hover {
    color: #FFFFFF;
    background: #BDBDBD;
  }
}

.btn-200 {
  width: 200px;
}
.reset-password {
  background: #F0F2F8;
  padding: 100px 0;

  h1 {
    margin: 0;
    font-size: 22px;
    @include proximanova-bold;
    color: #1B27A3;
    line-height: 120%;
  }
}
.auth_v2 {
  background: #F0F2F8;

  &-title {
    margin-top: 117px;
    font-size: 22px;
    @include proximanova-bold;
    color: #1B27A3;
  }

  &-description {
    margin-top: 35px;
  }

  .signin {
    width: 400px;
    min-height: 470px;
    background: #FFFFFF;
    margin-top: 75px;
    margin-bottom: 55px;
    margin-left: 140px;
    padding: 30px 50px 20px 50px;

    .alert {
      margin-bottom: 10px;
    }

    button[type="submit"] {
      margin-top: 20px;
    }

    &-title {
      font-size: 22px;
      @include proximanova-bold;
      color: #000000;
      text-align: center;
      ine-height: 120%;
    }

    .input-group {
      margin-bottom: 10px;

      &--input {
        width: 100%;
      }
    }

    &-links {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        font-size: 12px;
        line-height: 15px;
        color: #8692A6;
        text-decoration-line: underline;
      }
    }

    hr {
      width: 260px;
      margin-top: 20px;
      border: 1px solid #F0F2F8;
    }

    p {
      text-align: center;
      font-size: 14px;
      line-height: 130%;
    }

    &-action {
      text-align: center;
      margin-top: 20px;
    }
  }
}
*::after, ::before {
  box-sizing: border-box;
}

.alignnone {
  margin: 0;
  width: 100%;
  height: auto;
}

.aligncenter, div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  background: #fff;
  border: 2px solid $colorBlue;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
  color: $colorBlue;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

body {
  background-color: $colorWhite;
  @include proximanova-light;
  font-size: 16px;
  line-height: 1.5;
  color: $colorBlack;
}

a, a:hover, a:active, a:focus {
  color: $colorBlue;
}

strong {
  font-weight: 500;
}

h1, h2 {
  line-height: 48px;
  font-weight: 300;
  padding: 0;
  //margin: 0;
}

h3 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

h4 {
  font-size: 22px;
  line-height: 24px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
}

.cursor-p {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.justify-center {
  justify-content: center;
}

.bg-blue-easy {
  background-color: $colorBlue2;
}

.bg-blue {
  background-color: $colorBlue;
}

.bg-blue-2 {
  background-color: $colorBlue2;
}

.bg-blue-3 {
  background-color: $colorBlue3;
}

.bg-black {
  background-color: $colorBlack;
}

.bg-white {
  background-color: $colorWhite;
}

.color-blue {
  color: $colorBlue;
}

.color-white {
  color: $colorWhite;
}

.color-black {
  color: $colorBlack;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;

  a {
    text-decoration: none;
    color: $colorBlack;

    &:hover, &:active, &:focus {
      color: $colorBlack;
    }
  }

}

.button {
  padding: 7px 35px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  transition: 0.2s ease;

  &:hover, &:active, &:focus {
    text-decoration: none;
    outline: none;
  }

  &:disabled {
    opacity: .5;
  }
}

.btn-white {
  border: 2px solid $colorWhite;
  color: $colorBlue;
  background: $colorWhite;

  &:hover {
    background: transparent;
    color: $colorWhite;
  }
}

.btn-blue {
  border: 2px solid $colorBlue;
  color: $colorWhite;
  background: $colorBlue;

  &:hover {
    background: transparent;
    color: $colorBlue;

    &:disabled {
      color: $colorWhite;
      background: $colorBlue;
    }
  }
}

.btn-blue-empty {
  border: 2px solid $colorBlue;
  color: $colorBlue;
  background: transparent;

  &:hover {
    background: $colorBlue;
    color: $colorWhite;
  }
}

.btn-white-empty {
  border: 2px solid $colorWhite;
  color: $colorWhite;
  background: transparent;

  &:hover {
    background: $colorWhite;
    color: $colorBlue;
  }
}

.btn-black-empty {
  border: 2px solid $colorBlack;
  color: $colorBlack;
  background: transparent;

  &:hover {
    background: $colorBlack;
    color: $colorWhite;
  }
}

.btn-grey {
  border: 2px solid $colorGrey;
  background: $colorGrey;
  color: $colorWhite;
}

.font-bold {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.tooltip {
  padding: 10px;
  position: absolute;
  font-size: 14px;
  width: 210%;
  line-height: 18px;
  background: $colorWhite;
  left: 50%;
  top: 37px;
  border-radius: 2px;
  transition: all .5s ease;
  border: 1px solid $colorBlue;
  color: $colorBlack;
  display: none;
  z-index: 2;
  box-shadow: 0px 3px 9px 0 rgba(0, 0, 0, .25);

  &::before {
    content: "";
    height: 13px;
    width: 22px;
    position: absolute;
    top: -13px;
    left: -5px;
    background: url(/images/tooltip-arrow.png);
  }

  @media (max-width: 1440px) {
    width: 290%;
  }

  @media (max-width: 768px) {
    left: 0;
  }
}

.content-text {
  blockquote {
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
  }
}

.label-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    margin-right: 10px;
    color: #1e22aa;
}

.info-tip {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #EB5757;
    text-align: center;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 12px;
    margin: auto 18px;
}

.tippy-content {
    @include proximanova-light;
    background-color: #fff;
    box-shadow: 2px 2px 4px rgba(23, 38, 166, 0.1);
    opacity: 1;
    border-radius: 4px;
    font-size: 12px;
    padding: 10px 12px;
    line-height: 140%;
    z-index: 10;
    border: 1px solid #EB5757;
    text-align: left;
    max-width: 260px;
    color: #000;
}

.tippy-svg-arrow{
    bottom: -24px;
    z-index: 30;
}

.tippy-arrow {
    &:before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid
    }
}

.tippy-box {
    bottom: 10px;
}

.div-danger {
    border: 2px solid $colorRed3;
    height: 60px;
    display: table-cell; vertical-align: middle;
    &--input {
      color: $colorRed3;
      font-size: 10px;
      margin: auto 20px;
    }
}

.row_border {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #f0f2f8;
      bottom: 0;
      z-index: 0;
      background-clip: content-box;
      padding-right: 7.5px;
      padding-left: 7.5px;
    }
}

//tdi

.overflow-hidden {
  overflow: hidden !important;
}

.strong {
  @include proximanova-bold;
}

strong {
  @include proximanova-bold;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}


.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
    margin-bottom: 30px !important;;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-28 {
  padding-left: 28px !important;;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}
//--tdi

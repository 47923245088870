.form-doc-upload {
  padding: 28px 0;
  border-top: 0.5px solid #D8D8D8;

  &--noborder {
    border: none;
  }

  &--icon {
    padding-top: 10px;
  }

  &--body {
    padding: 0 40px;
    flex: 2;
  }

  &--body-v2_0 {
    padding: 10px 0 0 10px;
    flex: 2;
  }

  &--actions {
    display: flex;
    flex-direction: column;
  }

  &--action-links {
    a {
      font-size: 10px;
      line-height: 20px;
      margin-right: 10px;
    }
  }

  &--action-links-v2_0 {
    display: flex;
    margin-left: 28px;
    a {
      font-size: 12px;
      line-height: 20px;
      margin-right: 10px;
    }
  }

  &--title {
    margin: 0;
    font-size: 14px;
    @include proximanova-bold;
    color: $colorBlack;
    text-decoration: none;
  }

  &--date-upload {
    margin-top: 20px;
    font-size: 10px;
  }
  &--date-upload-v2_0 {
    margin-top: 20px;
    font-size: 12px;
  }

  &--status,  &--status-v2_0 {
    margin: 0;
    margin-top: 10px;
    font-size: 10px;

    span {
      margin-left: 13px;

      &.text-warning {
        color: #F2994A !important;
      }

      &.text-danger {
        color: #EB5757 !important;
      }

      &.text-success {
        color: #27AE60 !important;
      }
    }
  }
  &--status-v2_0 {
      font-size: 12px;
      text-align: center;
  }
  &--title-block-v2_0 {
      display: flex;
  }
}
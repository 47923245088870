.form-registration-box {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  display: flex;

  .form-registration-sidibar {
    flex: 0 0 520px;
    background: rgba(30, 34, 170, 0.6);
    background-image: url("../images/background_PP.png");
    background-size: cover;
    padding-left: 80px;
    padding-top: 115px;

    h1 {
      color: $colorWhite;
      margin: 0;
    }
  }

  .form-registration-body {
    padding: 115px 80px;

    h2 {
      @include proximanova-regular;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
    }
  }
}

.form-registration-steps {
  margin: 30px 0;
}

.form-registration-step {
  display: flex;
  margin-bottom: 20px;

  .form-registration-step-point {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    color: #1e22aa;
    font-size: 30px;
    line-height: 60px;
    text-align: center;

    &::after {
      content: "";
      height: 20px;
      display: block;
      border-right: 0.5px solid rgba(255, 255, 255, 0.4);;
      width: 50%;
    }
  }

  &--last {
    .form-registration-step-point::after {
      border: none;
    }
  }

  .form-registration-step-text {
    margin-left: 19px;
    line-height: 60px;
    @include proximanova-bold
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
  }

  &--active {
    .form-registration-step-point {
      background: $white;
    }

    .form-registration-step-text {
      color: $white;
    }
  }

  &--half-active {
    .form-registration-step-point {
      background: linear-gradient(to top, rgba(255, 255, 255, 0.4) 50%, $white 50%);
    }

    .form-registration-step-text {
      color: $white;
    }
  }

  &.form-registration-step-action {
    .form-registration-step-point {
      margin-left: 20px;
      height: 20px;
      width: 20px;
      line-height: 20px;
    }

    .form-registration-step-text {
      line-height: 20px;
      margin-left: 39px;
      @include proximanova-light;
    }
  }
}

.form-registration {
  &-group {
    margin-top: 30px;
    display: flex;

    &--number {
      flex: none;
      height: 26px;
      width: 26px;
      background: rgba(30, 34, 170, 0.4);
      margin-right: 18px;
      display: inline-block;
      border-radius: 50%;
      font-size: 16px;
      color: $white;
      line-height: 26px;
      text-align: center;
    }

    &--label {
      //@include proximanova-bold;
      font-size: 14px;
      letter-spacing: -0.015em;
    }

    &--inline {
      display: flex;
    }
  }

  .input-group, .checkbox-group, .radio-group {
    margin-top: 20px;
  }

  small {
    display: block;
    font-size: 10px;
  }
}

ol {
  list-style: none;

  li {
    margin-top: 15px;

    b {
      @include proximanova-bold;
      font-size: 18px;
      width: 24px;
      display: inline-block;
    }
  }
}

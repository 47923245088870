.alert {
  background: $gray-400;
  border: 1px solid $gray-600;
  padding: 10px 20px;

  &-danger {
    border-color: $colorRed;
    background: $colorRed2;
    color: $white;
  }
}
footer {
    background: $colorBlack;
    min-height: 180px;
    padding: 30px 0;
    color: $colorWhite;

    .footer-menu {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0 0 20px 0;
        }

        a {
            text-decoration: none;
            color: $colorWhite;
        }
    }

    .footer-bottom {
        margin-top: 20px;
        font-size: 12px;

        a {
            color: $colorWhite;
        }
    }

    .footer-logo {
        text-align: right;

        img {
            width: 100px;

            @media (max-width: 768px) {
                position: absolute;
                right: 15px;
                top: -690px;
            }
        }
    }

    .footer-policy {
        a:hover {
            text-decoration: none;
        }
    }
}

@media (max-width: 768px) {
    footer {
        min-height: 50px;
    }
}

#modal {
    max-width: 620px;

    @media(max-width: 768px){
        padding: 30px;
    }

    input[type="text"], input[type="email"], input[type="tel"], textarea {
        width: 100%;
        font-size: 14px;
        border: 1px solid $colorBlack;
        padding: 10px 15px;
        margin: 0 0 20px 0;
    }

    textarea {
        height: 100px;
    }

    input[type="submit"] {
        padding: 10px 25px;
        cursor: pointer;

        @media(max-width: 768px){
            width: 100%;
        }
    }
}
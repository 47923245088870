.existing-company {
  background: #F0F2F8;
  padding: 100px 0;

  h1 {
    margin: 0;
    font-size: 22px;
    @include proximanova-bold;
    color: #1B27A3;
  }

  .company {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    width: 440px;
    border: 1px solid #221EAA;

    div.d-flex {
      margin-top: 30px;
    }

    &-title {
      width: 100px;
      min-width: 100px;
    }
  }
}

.modal {
  &-body {
    padding: 70px 70px;
  }

  &-title {
    font-size: 22px;
    color: #1E22AA;
    font-weight: 600;
  }

  &-close {
    width: 16px;
    height: 15px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    background-image: url("data:image/svg+xml, %3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1L15 14.5' stroke='black'/%3E%3Cpath d='M14.5 1L0.999999 14.5' stroke='black'/%3E%3C/svg%3E");
  }

  &-content {
    border-radius: 0px;
  }

  &-dialog {
    max-width: 700px!important;
  }
  &.fade.show {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7px);
  }
}

